// main : stylesheet.scss
$lr-padding : 15px; // for the main containers
.old-price{
  text-decoration: line-through;
}
#products-app{
  ul{
    list-style: none;
    padding-left: 20px;
    li::before{
      content: "\25A0";
      color: $pink;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

  }

  .advice{
    padding:10px;
    color: white; 
    background: #858585;
    border-radius: 3px; 
  }

  .gallery {
    text-align: center;
    a {
      width: 75px; height: 75px; border: 2px solid #ccc; vertical-align: top;
      position: relative;
      margin: 5px;
      img {
        position: absolute;
        left: 50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  .product-type{
    padding: 0px $lr-padding 10px $lr-padding;
    flex-wrap: nowrap;

    .triangle{
      color: $turqoise;
      font-size: 20px;
      padding-right: 10px;
    }
  }

  .buymore{
    border:5px solid #dedede !important;
    padding: 15px;
    width: 100%;
    margin-bottom: 10px;
    @media (max-width: 990px){
      margin-top: 10px;
    }
  }

  #desktop-description{

    margin-left: 20px;
    flex:30%;
    flex-direction: column;


    @media (min-width: 991px){
      display: flex;
    }
    @media (max-width: 990px){
      display: none;


    }
  }
  #desktop-description, #desktop-description2{
    .text{
      padding:15px;
      border:5px solid #dedede;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 100%;
    }
  }
  .description{
    padding: 15px;
    border: 5px solid #dedede;
    width: 100%;
    margin-top: 10px;
    img{
      max-width: 100%;
    }
  }
  .confidence{
    @media (min-width: 1024px)
      and (max-height: 768px) {
        display: none !important;
    }
    border:5px solid #dedede;
    align-self: flex-end;
    width: 100%;
    padding:15px;
    font-weight: bold;
    margin-top: 10px;
    .rosette{
      padding-top: 5px;
      img{
        padding:5px 5px 0 0;
      }
    }
    .lorry{
      border-bottom:1px solid #dedede;
      padding-bottom: 5px;
      img{
        padding:5px 5px 5px 0;
      }
    }
  }
  #desktop-image{
    border:5px solid #dedede !important;
    width: 100%;

    @media (max-width: 991px){
      display: none;


    }
  //  @media (max-width: 1170px){
      img{
        max-width: 100%;
      }


  //  }
  }

  #name-price{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  #desktop-description2{
    display: none !important;
  }

  @media (min-width: 991px) and (min-height: 550px){
    #desc-options{
      &.compact{
        max-width: 1470px;

        #maintitle{
          flex : 20%;
          @media (min-width: 1300px){
            max-width:450px;
          }
        }
        #desktop-description{

          //flex:20%;
          //@media (max-width: 1300px){
            display: none !important;
          //}
          //@media (min-width: 1300px){
          //  flex:15%;
          //  max-width: 340px;
          //}
        }
        #desktop-image, .confidence{
          //@media (max-width: 1300px){
            display: none !important;
          //}
        }

        #desktop-description2{
          //@media (max-width: 1300px){
            display: flex !important;
          //}
        }
      }
      max-width: 1170px;
      margin: 20px auto;
      //margin-top: 40px;
      display: flex;
      h2,h3{
        text-align: left !important;
        font-weight: normal;
      }
      h2.bundle_options,h3.bundle_options{
          margin-top: 10px;
          font-weight: bold !important;
          span{
            font-weight: normal;
          }

      }


      #maintitle{
        //align-items: stretch;
        flex-direction: column;
        justify-content: space-between;
        padding : 0;
        padding-left: 20px;
        max-width: 370px;
        flex:35%;
        .price{
          margin-bottom: 10px;
          border-radius: 3px;
        }

      }

      .desktop-section{

        padding:15px;

      }

      .options{
        flex:35%;
        border:5px solid #dedede !important;
        border-bottom: 5px solid #dedede !important;
        background: white !important;
        text-align: left;
        margin-left: 20px;
        margin-right: 20px;
        padding: 15px 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      #warning{
        display: none !important;
      }
    }
    h2{
      font-size: 30px;

    }
    .choose-photos{
      padding:20px 0px 30px 0px;
      background: #ececec;
      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
    }

  }
  .container{
    @media (max-width: 1023px){
      max-width: none;
    }
  }
  #maintitle{
    justify-content: space-between;
    .alert{
      width: 100%;
    }
  }
  #allcategory-list{
    width: 100%;
    max-width: 1170px;
  }
  #top-categories{
    justify-content: center;
    min-height: 40px;
    font-size: 20px;
    margin-bottom: 10px;
    .fa-chevron-circle-down{
      display: none;
    }
    @media (min-width: 991px) and (min-height: 650px){
      min-height: 50px;
      .fa-chevron-circle-down{
        display: inline-block;
      }
    }

    a{
      cursor: pointer !important;
      font-family: Brandon;
      margin-right: 3px;
      margin-bottom: 3px;
      flex:49%;

      @media (min-width: 736px) {
          flex:32%;

      }
      @media (min-width: 991px) {
          flex:24%;

      }

      align-items: center;
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: center;
      @media (min-width: 991px) and (min-height: 650px){
        line-height: 23px;
        font-size: 25px;
        padding-left: 20px;
        padding-right : 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-right: 4px;
        justify-content: space-between;
      }
    }
  }
  #category-list{
    justify-content: center;
    @media (min-width: 768px) and (min-height: 650px){

    }
    a{
      cursor: pointer !important;

      margin-right: 3px;

      margin-bottom: 3px;
      flex:49%;


      @media (min-width: 736px) {
          flex:32%;

      }
      @media (min-width: 991px) {
          flex:16%;

      }

      align-items: center;
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      background: $dark-grey;
      border-radius: 4px;
      &:hover{
        background: #666;
      }
      &.active{
        background: $pink !important;
      }
    }
  }

  #catalog-list{
    @include catalog-list;

  }
  #related-list{
    @include catalog-list;
    a.check{
      color: #ccc;
      top: 0px;
      position: absolute;
      right: 6px;
      font-size: 27px;
      &.selected{
        color:$pink;
      }
    }

  }
  .related-title{
    padding:20px 20px 0 20px;
    font-weight: 300;
    text-align: center;
  }


  #configure{
    h4{
      font-weight: normal;
      font-size: 16px;
    }
    .title-fixed{
      position: fixed;
      top:0;
      background: white;
      border-bottom: 1px solid #cecece;
      width : inherit;
      min-width: 100%;
      h2{
        font-size: 16px;
      }
      .price{
        font-size: 14px;
      }
      z-index: 90;
      .alert{
        width:100%;
      }
    }
    .title, .title-fixed{
      padding:10px $lr-padding;
      align-items: center;
      flex-wrap: wrap;

      h2{
        display: inline-block;
        padding-right: 10px;
      }
      .info{
        color: $pink;
        font-size: 25px;
        line-height: normal;
      }
      .closeinfo{
        font-size: 21px;
        line-height: normal;
        float: right;
      }
      .price{
        background: $turqoise;
        display: inline-block;
        padding:5px 10px;
        color: white;
        font-family: "Brandon";
        font-size: 19px;
      }
    }

    .options{
      &.compact{
        padding-top: 15px;
        padding-bottom: 20px;
        .option-values{
          display: flex;

          @media (max-width: 600px){
          //  &.column{
              flex-direction: column;
        //    }
          }
          .name{
            margin-bottom: 5px;
          }
          .values{
            justify-content: flex-start;
            a{
              font-size:14px;
              font-weight: normal !important;
              padding: 4px 7px;
              margin-left: 0;
              margin-right: 3px;
            }
          }


        }

      }
      .optionslist{
        visibility: visible;
        opacity: 1;
        transition: opacity 0.35s ease-in-out;
        &.changing{
            opacity: 0 !important;
        }

      }
      padding:20px 0px 30px 0px;
      background: #ececec;
      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      h2{
        text-align: center;
        margin-bottom: 15px;
        span{
          font-weight: normal;
        }
      }

      .name{
        flex: 0 1 25%;
        font-weight: bold;
        display: flex;
        //align-items: center;
        font-size: 15px;
        &.error{
          span{
            font-weight: normal;
            color: red;
          }
        }
      }
      .option-values{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;



      }
      .values{
        flex: 1 1 75%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        a.btn.active{
          background: $turqoise !important;
        }
        a.btn{
          background: #858585;
        }
        a{
          @extend .squarer-button;
          border-radius: 3px !important;
          font-weight: bold;
          padding-left:15px;
          padding-right: 15px;
          margin-left: 3px;
          margin-bottom: 3px;

        }
    }
    }
    .choose-photos{
      .alert-info{
        margin: 0 10px 10px 10px;
      }

      #imageSizeToggle{
        position: absolute;
        right: 15px;
        top:-13px;
        @media (min-width: 991px){
          display: none;
        }
        #bigImages{
          font-size: 30px;
          &.active{
            color: $pink;
          }
        }
        #notbigImages{
          font-size: 20px;
          &.active{
            color: $pink;
          }
        }
      }


      padding:20px 0px 30px 0px;
      .header{
        text-align: center;
        position: relative;
        h2{

          margin-bottom: 15px;
        }

      }


      .images{
        visibility: visible;
        opacity: 1;
        transition: opacity 0.35s ease-in-out;
        &.changing{
            opacity: 0 !important;
        }
        @include imagethumbs;

        margin: 0;
        justify-content: center;

        .image{
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          padding: 0 7px 0 8px;
          width:24%;
          max-width: 333px;
          &.wide{
            width:49%;
            max-width: 700px;
          }
          @media (max-width: 500px){
            width:48%;
            &.wide{
              width:98%;
            }
          }

          @media (min-width: 1470px){
            width:19%;
            &.wide{
              width:39%;
            }
          }
          .zoom{
            @media (min-width: 991px){
              display: none;
            }
            .fa-times{
              padding:5px;
            }
            left: 14px;
            top: 26px;
            color : #555;
            font-size: 21px;
            position: absolute;
          }
          .check{
            &.selected{
              color: $pink;
            }
            color: #ccc;
            top: 26px;
            position: absolute;

            position: absolute;
            right: 14px;
            font-size: 21px;
            @media (min-width: 991px) and (min-height: 650px){
              font-size: 25px;
            }
          }
          &.bigger{
            width: 99%;
          }
        }


      }
    }

  }
}
