// main : stylesheet.scss
#faq-modal, #cropModal{
  img{
    max-width: 100%;
  }
  .modal-header{
    display: block;
    margin: 0;
    padding: 20px 30px;
    background: #5db8c0;
    border-radius: 0;
    .close-help{
      position: absolute;
      right: 17px;
      top: 11px;
      color: #666;
      font-size: 35px;
      text-align: center;
      cursor: pointer;
      z-index: 100;
      opacity: 1;
    }
    text-transform: uppercase;
    h5{
      font-size: 30px;
      color: #fff;
    }
  }

}
#faq-modal{
  .modal-body{
    padding:0;
  }
  .accordion-heading{
    padding:15px;
    background: $dark-grey;
    border-bottom: 1px solid white;
  }
  .accordion-body{
    padding: 15px;
  }
  .accordion-toggle{
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: white;

  }
}
#cropModal{

}
