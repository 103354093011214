// main : stylesheet.scss
.upper{
  background: $pink;
  @media (min-width: 768px){
    display: none;
  }
}
#header{

  border-bottom: 1px solid #dedede;

  header{
    padding:0;
  }
  .logowrap{
    .logo{
      height: 68px;
      padding-left: 15px;
      @media (max-width: 767px) {
          max-height: 50px;
      }
      max-height: 68px;
    }
  }

  .topnav{
    height: 50px;

    a#mobile-basket-button{
      color:$pink;
      font-family: "Brandon";
      padding: 10px;
      font-size: 21px;
      padding-right: 30px;
      display: inline-block;
      .numberwrap{
        position: relative;
        top: -27px;
        left:28px;
      }
      .number{
        background: $pink;
        color: white;
        border-radius: 50%;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21px;
        .text{
          position: relative;
          font-size: 14px;
        }
      }
    }
    a{

    }
    a.menutoggle{
      color:$pink !important;
      background: #d9d9d9;
      width:55px;
      display: block;
      vertical-align: middle;
      background: #eee;
      color: white;
      font-size: 20px
    }
  }
  .desktopnav{
    padding-right: 20px;
    a{
      font-size: 14px;
      font-family:"Brandon";
      text-transform: uppercase !important;
      display: inline-block;
      color:#666;
      font-weight: bold;
      padding:15px;
      @media (max-width: 1024px){
        padding:10px;
      }
      @media (max-width: 850px){
        padding:6px;
        font-size: 94%;
        .optional{
          display: none;
        }
      }
      &:hover{
        text-decoration: none;
        color: $pink;
      }
    }
  }
  .desktop-pink{
    .icon-svg-edit-account{

    }
    background: $pink;
    color: white;
    text-align: right;
    padding:0 25px;
    a{
      color:white;
      font-family: "Brandon";
      padding: 10px;
      font-size: 14px;
      display: inline-block;
      &:hover{
        text-decoration: none;
        color: #f99cd0;
      }
    }
  }
  .sidenav {
      width: 250px;
      transform: translateX(250px);
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 1100;
      background: white;
      color: #fff;
      transition: all 0.3s;
      .menuheader{
        background: $dark-grey;

        .closemenu{
          color:white;
          padding:10px
        }
      }
      .menubody{
        a{
          color: $dark-grey;
          background: #eee;
          padding:15px 30px;
          display: block;
          font-family: Brandon;
          border-bottom: 1px solid white;
          font-weight: 700;
          font-size: 17px;

          &:hover{
            background: $turqoise;
            color:white;
            text-decoration: none;
          }
        }
      }
      &.out{
        transform: translateX(0px);
        -webkit-box-shadow: -2px 0px 3px 0px rgba(0,0,0,0.5);
-moz-box-shadow: -2px 0px 3px 0px rgba(0,0,0,0.5);
box-shadow: -2px 0px 3px 0px rgba(0,0,0,0.5);
      }
      .close{
        background: $dark-grey;
        a{
          color : white;
        }
      }
  }
}
