// main : stylesheet.scss

@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.font-brandonT {
    font-family: 'Brandon';
    font-weight: normal;
    &.thin {
        font-weight: 100;
    }
    &.light {
        font-weight: 300;
    }
    &.medium {
        font-weight: 500;
    }
    &.regular {
        font-weight: normal;
    }
    &.bold {
        font-weight: bold;
    }
    &.black {
        font-weight: 900;

    }
}

@font-face {
  font-family: "tor-icons";
  src:url("fonts/tor-icons.eot");
  src:url("fonts/tor-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/tor-icons.woff") format("woff"),
    url("fonts/tor-icons.ttf") format("truetype"),
    url("fonts/tor-icons.svg#tor-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "tor-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "tor-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-svg-change-password:before {
  content: "\62";
}
.icon-svg-edit-account:before {
  content: "\63";
}
.icon-svg-edit-address:before {
  content: "\64";
}
.icon-svg-emailfriends:before {
  content: "\65";
}
.icon-svg-photo-download:before {
  content: "\66";
}
.icon-svg-previous-order:before {
  content: "\67";
}
.icon-svg-search:before {
  content: "\68";
}
.icon-svg-tick:before {
  content: "\69";
}
.icon-svg-uid:before {
  content: "\6a";
}
.icon-svg-warning:before {
  content: "\6b";
}
.icon-svg-info:before {
  content: "\61";
}
.icon-cancel:before {
  content: "\6c";
}

.icon-svg-info:before {
  content: "\61";
  color:white;
  background: $pink;
  border-radius: 50%;
}

.icon{
  font-family: "tor-icons";
}


@font-face {
    font-family: 'Brandon';
    src: url('fonts/BrandonText-Bold.woff2') format('woff2'),
        url('fonts/BrandonText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon';
    src: url('fonts/BrandonText-Light.woff2') format('woff2'),
        url('fonts/BrandonText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon';
    src: url('fonts/BrandonText-Regular.woff2') format('woff2'),
        url('fonts/BrandonText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brandon';
    src: url('fonts/BrandonText-Thin.woff2') format('woff2'),
        url('fonts/BrandonText-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
