// main : stylesheet.scss
#featured-modal{
    .btn-add{
        display: none;
    }

    .modal-dialog{
        .modal-content{
            .modal-header{
                padding: 0;
                border-bottom: 0px solid #e5e5e5;
                display: block;
                h5{

                        margin: 0;
                        padding: 20px 30px;
                        background: $turqoise;
                        color: #fff;
                        font-size: 30px;
                        text-transform: uppercase;
                }
                .inner{
                    padding: 15px 30px;
                    p{
                        font-weight: bold;
                        line-height: 21px;
                    }

                    hr{
                        border-top: 1px solid #e5e5e5;
                        margin: 30px 0 0;
                    }
                }

                .close-button{
                        position: absolute;
                        right: 17px;
                        top: 21px;
                        color: #666;
                        height: 70px;
                        width: 60px;
                        font-size: 35px;
                        text-align: center;
                        cursor: pointer;
                        z-index: 100;
                        opacity: 1;
                        span{
                            font-size: 43px;
                        }

                        &:hover{
                            span{
                                color: #BF517F;
                            }
                        }
                }
            }
            .modal-body{
                padding: 15px 30px;
                img{
                        margin: 0 auto;
                        width: 130px;
                        max-width: 100%;
                        margin-bottom: 10px;
                    }
                .inner{
                    padding-left: 20px;

                    @media(max-width: 767px){
                        padding-left: 0;
                    }

                    h4{
                        color: #999999;
                        font-size: 30px;
                        @media(max-width: 767px){
                            font-size: 25px;
                        }
                        margin-top: 0;
                        .price{
                            font-weight: 900;
                            color: #666;
                            margin-bottom: 25px;
                        }
                    }

                    .desc{
                        margin-bottom: 25px;

                        p{
                            margin-bottom: 5px;
                        }
                    }
                    hr{
                        border-top: 1px solid #e5e5e5;
                        margin: 25px 0 ;
                    }

                }

                textarea{
                    font-size: 16px;
                }


                .unsure{
                    h3{
                        font-size: 30px;
                        margin-top:10px;
                        @media(max-width: 767px){
                            font-size: 25px;
                        }
                    }
                    a{
                        color: #bf517f;
                        &:hover, &:focus{
                            color: darken(#bf517f, 10%);
                            text-decoration: none;
                        }
                    }

                }
            }
            .modal-footer{
                padding: 0;
                border-top: 0px;
                .inner{
                    padding: 15px 30px 30px;

                    hr{
                        border-top: 1px solid #e5e5e5;
                        margin: 0 0 20px;
                    }
                }
            }
        }
    }



}
