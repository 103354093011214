// main : stylesheet.scss
@mixin imagethumbs {
  margin:0 -15px;
  .owl-dot{
    display: none;
  }
  .image{
    padding:0 15px;
    position: relative;


    text-align: center;
    .filename{
      text-transform: uppercase;

    }
    .filename, .qr{
      font-weight: bold;
      font-size: 12px;
    }
    img{
      position: relative;
      margin-top: 5px;
      border:3px solid #dedede;
      max-width: 100%;
    }
    .loading{
      height: 120px;
      border:3px solid #dedede;
    }
  }
}

@mixin catalog-list{
  margin-top: 15px;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 768px){
      justify-content: space-evenly;
    }
  .product-container{
    &.extra{
      visibility: hidden;
    }
    cursor: pointer;
    flex: 1 1 280px; /* will grow to halfway - margin anyway */
    margin-right: 15px;
    @media (max-width: 530px){
      margin-left: 0;
      margin-right: 0;
      max-width: none;
    }
    @media (max-width: 768px){

      &:nth-of-type(even){
          margin-right: 0;
      }

    }
    max-width: 350px;
    border : 3px solid #dedede;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom:15px;
    padding:1px;

    .image{
      position: relative;
      width: 100%;
      height: 100%;
      .description{
        border: none;
        margin-top: 0;
        position: relative;
        padding:10px;
        padding-top: 15px;
        background: #333;
        color: white;
        .advice{
          margin-bottom: 10px;
        }
        article{
          padding-bottom: 10px;
        }

      }
      img{
        width: 100%;
      }
      a.info{
        position: absolute;
        top: 10px;
        right:10px;
        color: $pink;
        font-size: 35px;
        line-height: normal;
      }
      a.closeinfo{
        position: relative;
            float: right;
            /* top: 10px; */
            /* right: 10px; */
            font-size: 20px;
            z-index: 90;
            margin-left: 20px;
            line-height: normal;
      }
    }

    .name-price{
      display: flex;
      justify-content: space-between;
      font-size: 19px;
      font-family: "Brandon";
      width: 100%;
      flex: 0 1 15%;
      border-top: 1px solid white;
      min-height: 61px;
      .name{
        display: flex;
        align-items: center;
        background: #858585;
        width: 60%;
        color: white;
        padding:10px;
        line-height: 20px;
      }
      .price{
        display: flex;
        align-items: center;
        justify-content: center;
        background: $turqoise;
        width: 40%;
        color: white;
        padding:10px;
        text-align: right;
      }
    }

  }
}

@mixin greybar {
  width: 100%;
  justify-content: center;
  background: #f4f4f4;
  border-top:1px solid #dedede;
  border-bottom:1px solid #dedede;
  padding-top: 20px;
  padding-bottom: 20px;
}

@mixin title {
  margin-top: 15px;
  @media (min-width : 768px) and (min-height: 768px){
    margin-top: 30px;
    h2{
      font-size: 30px;
    }
  }
}

@mixin jstable {
  border:3px solid #959595;
  table{
    margin-bottom: 0;
  }
  th{
    background: $dark-grey;
    color: white;
  }
  tbody{
    background: white;
  }
  td, th{
    border: none;
    img{
      border:3px solid #dedede;
      @media (max-width: 400px){
        max-width: 179px;
      }
    }
  }
  tr.even{
    background: #eee;
  }
}
