// main : stylesheet.scss

.jssp-alert{
  color: white;
  padding:10px;
  margin-bottom: 10px;
}

#checkout{
  @include greybar;
  .panel{
    .panel-heading{
      background: $dark-grey;
      color: white;
      padding:10px;
      font-weight: normal;
      border-bottom: 1px solid white;
      a{
        color: white;
        &:active{
          color: $turqoise;
        }
      }
    }
    .panel-body{
      border: 1px solid #dedede;
      padding-bottom: 10px;
      background: white;
      padding:20px;
      @media (max-width: 600px){
        padding:15px;
      }
      .buttons{
        min-height: 45px;
      }
    }
    .tableouter{
      @include jstable;

      @media (max-width: 600px){
        margin-left: -15px;
        margin-right: -15px;
        border:none;
      }
    }
    .btn{
      margin-right: 15px;
    }
    .datacheckbutton{
      margin-left: 15px;
    }
    .form-group{
      display: flex;
    }
  }
  .pull-right{
    float: right;
  }
  #paymentshow{
    @media (max-width: 600px){
      margin-left: -15px;
      margin-right: -15px;
      border:none;
      .form-group{
        flex-wrap: wrap;
      }
      .col-sm-2{
        width:100%;
      }
    }
  }
}
