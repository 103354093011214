// main  : stylesheet.scss

#downloads-app{
  #catalog-list{
    @include catalog-list;
  }
}


#account-page{

  .box{
    padding:15px;
    margin-bottom: 15px;
  }

  .title{
    @include title;
    h2{
      margin-bottom: 15px;
    }
  }

  .tableouter{
    @include jstable;
    margin-bottom: 15px;
  }



  .grey-bar{
    @include greybar;
    margin-bottom: 30px;
    h2{

    }
    h3{
      font-weight: normal;
      text-align: center;
      font-size: 21px;
      margin-bottom: 15px;
    }
    .buttons{
      display: flex;
      text-align: center;
      justify-content: center;
      a{
        cursor: pointer;
        color: white;
        padding: 15px;
        font-family: Brandon;
        border-radius: 4px;
        margin-right: 1px;
        font-weight: bold;
        font-size: 17px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .icon{
          font-size: 40px;
        }
        &:hover{
          text-decoration: none;
        }
        max-width:200px;

      }
    }
    &.settings{
      .buttons{
        a{
          background: $pink;
          width: 33%;
          &:hover{
            background: #9b1f63;
          }
        }
      }
    }
    &.orders{
      .buttons{
        a{
          background: $turqoise;
          &:hover{
            background: $turqoise-dark;
          }
          width: 50%;
          max-width: 200px;
        }
      }
    }
  }
}
