// main : stylesheet.scss
#footer, #productfooter{
  background: #efefef;
  text-align: center;
  border-top: 1px solid #dfdfdf;
  color:#999;
  &.pink{
    background: $turqoise;
    &:hover{
      background: $turqoise-dark;
    }
    color:white;
    font-size: 15px;
    @media (min-width: 768px){
      font-size: 19px;
    }
    font-weight: bold;
    @media (min-height: 768px) and (min-width: 1024px){
      font-size: 30px;
    }
    font-family: "Brandon";
    a{
      color:white;
      padding : 1.1rem;
      width: 100%;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &.pink.two{
    background: linear-gradient(
        to right,
        #000,
        #000 50%,
        $turqoise 50%,
        $turqoise 100%
      ) !important;
      a{
        width: 50%;
      }
  }
  &.pink.two.all-three{
    background: linear-gradient(
        to right,
        #000,
        #000 33%,
        $turqoise 33%,
        $turqoise 66%,
        $pink 66%,
        $pink 100%
      ) !important;
      a{
        width: 50%;
      }
  }

}
