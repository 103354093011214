// main : stylesheet.scss

#cart{
  background: #ececec;
  @include greybar;
  .pupilthumb{
    position: relative;
    display: inline-block;
    .filename{
      text-transform: uppercase;
    }
    img{
      position: relative;
      margin-top: 5px;
      border:3px solid #dedede;
      max-width: 100%;

    }
  }
  .tableouter{
    @include jstable;
  }
  .totals{
    tbody{
      background: #dedede;
    }
    margin-bottom: 10px;
  }
  .buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }


}
#paymentshow{
  border: 3px solid #959595;
  margin-top: 20px;
  legend{
    padding:15px;
  }
}
