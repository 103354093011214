#mailoptions{
    h1,h2,h4,h3{
        font-weight: normal;
    }
    .options{
    
      .optionslist{
        visibility: visible;
        opacity: 1;
        transition: opacity 0.35s ease-in-out;
        &.changing{
            opacity: 0 !important;
        }

      }
      padding:20px;
      border: 3px solid #dedede;
      margin-bottom: 30px;
      h2{
        text-align: center;
        margin-bottom: 15px;
        span{
          font-weight: normal;
        }
      }

      .name{
        flex: 0 1 50%;
        font-weight: bold;
        display: flex;
        //align-items: center;
        font-size: 15px;
        &.error{
          span{
            font-weight: normal;
            color: red;
          }
        }
      }
      .option-values{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;



      }
      .values{
        flex: 1 1 75%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        a.btn.active{
          background: $turqoise !important;
        }
        a.btn{
          background: #858585;
        }
        a{
          @extend .squarer-button;
          border-radius: 3px !important;
          font-weight: bold;
          padding-left:15px;
          padding-right: 15px;
          margin-left: 3px;
          margin-bottom: 3px;

        }
    }
    }
}