// main : stylesheet.scss


#login-register{


  label{

        @media (max-width: 1199px) {
            font-size: 18px;
        }
        @media (max-width: 991px) {
            font-size: 16px;
        }
        @media (max-width: 767px) {
            font-size: 14px;
        }
        font-size: 24px;
    }

    .fancy-label{
      .form-group {
            position: relative;
            margin-bottom: 25px;
            margin-top: 25px;
        }
        position: relative;
        margin-bottom: 35px;
        label{
            position: absolute;
            top: 8px;
            font-size: 21px;
            pointer-events: none;
            left: 10px;
            transition: .3s;
            font-size: 14px;
        }
        input:focus ~label,
            input:valid ~label
            {
            left: 0px;
            top: -20px;

            font-size: 12px;
        }
        input:focus,input:valid
            {
            //border: 1px solid green;


        }
    }





}
