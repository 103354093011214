// out: ../stylesheet.css, compress: true, sourceMap: false, autoprefixer: last 5 versions, groupmedia: true  
$turqoise : #5db8c0;
$turqoise-dark : #488c92;
$turqoise-darker : #2e6065;
$red : #bf0000;
$pink : #be2d7e;
$dark-grey : #666;

@import '_mixins.scss';
@import '_header.scss';
@import '_footer.scss';
@import '_checkout.scss';
@import '_featured.scss'; // checkout modal
@import '_modal.scss'; // faq/other modal
@import '_swal.scss';
@import '_account.scss';
@import '_mailoptions.scss';
@import '_cart.scss';
@import '_login-register.scss';
@import '_select-images.scss';
@import '_select-products.scss';
@import '_fonts.scss'; 
@import '_quick-checkout.scss';

body{
  color:#666; 
  font-family: 'Lato', sans-serif;
  font-size:14px;
  margin-bottom: 95px;
}
a{
  cursor: pointer !important;
}

th{
  color: $dark-grey;
}

.flex-center{
  display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
    position: relative;
}

.flex-center-100{
  @extend .flex-center;
  padding-left: 0;
  padding-right: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Brandon";
    font-weight: 900;
}

h1, .h1{
  font-size: 24px;
  @media(min-width: 768px) {
      font-size: 30px;
  }
}
h2, .h2{
  font-size: 24px;
}
h3, .h3{
  font-size: 17px;
}

.btn{
  background: $turqoise;
  border:none;
  color:white !important;
  font-family: "Brandon";
  border-radius: 18px;
  padding-left: 25px;
  padding-right: 25px;
  &:hover{
    background: $turqoise-dark;
  }
  &:active, &.active{
    background: $turqoise-darker !important;
  }
  &.pink{
    background: $pink;
  }
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

.form-control{
  border: 3px solid #dedede;
  font-size: 14px;
  @media (max-width: 1024px){
    font-size: 16px;
  }
  &:focus{
    box-shadow: none;
    border-color: $turqoise;
  }
}


small{
  a{
    display: inline-block;
    color : #007bff !important;
    font-size: small;
  }
}


.breadcrumbs{
  background: #efefef;
  -webkit-box-shadow: inset 0px 10px 12px -10px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 10px 12px -10px rgba(0,0,0,0.75);
  box-shadow: inset 0px 10px 12px -10px rgba(0,0,0,0.75);
  border-bottom: 1px solid #dfdfdf;
  font-size: 12px;
  .container{}
  .gt{
    display: inline-block;
    padding: 0 5px;
  }
  a{
    color:inherit;
  }
}

.alert-success{
  background: $turqoise;
  border-color:$turqoise-dark;
  color:white;
}
.text-info{
  color:$turqoise !important;
}
.alert-info{
  background: $turqoise;
  color: white;
}

.box{
  border: 3px solid #dedede;
}

.squarer-button{
  line-height: 18px;
  border-radius: 8px;
}

.two-line-button{
  @extend .squarer-button;
  padding-left: 10px;
  align-items: center;
  min-height: 50px;
  text-align: left;
  .icon2{
    display: none;
  }

  font-weight: bold;
  .icon{
    text-align: center;
    font-size: 30px;
    height: 30px;
    @media (min-height: 768px) and (min-width: 991px){
      font-size: 35px;
      height: 35px;
    }
  }
  @media (min-height: 768px) and (min-width: 991px){
    min-height: 50px;
    font-size: 25px;
    padding-right: 10px;
    min-width: 390px;
    justify-content: space-between;
    .icon1{
      display: none;
    }
    .icon2{
      display: inline-block;
      padding-left: 20px;
    }
  }
}

.fancy-label{
  .form-group {
        position: relative;
        margin-bottom: 25px;
        margin-top: 25px;
    }
    position: relative;
    margin-bottom: 35px;
    label{
        position: absolute;
        top: 8px;
        font-size: 21px;
        pointer-events: none;
        left: 10px;
        transition: .3s;
        font-size: 14px;
    }
    input:focus ~label,
        input:valid ~label
        {
        left: 0px;
        top: -20px;

        font-size: 12px;
    }
    input:focus,input:valid
        {
        //border: 1px solid green;


    }
}

.form-error{
  font-size: small;
  color : $red;
}

.toast-header{
  background: $turqoise;
  color: white;
  font-weight: normal;
  font-family: Brandon;
  font-size: 16px;
  font-weight: 400px;
}
.toast-body{
  background: white;
  border-color: #dedede;
}
