// main : stylesheet.scss

.swal2-modal{
  .swal2-confirm{
    background: $turqoise;
  }
  .swal2-cancel{
    background: black;
  }
}
