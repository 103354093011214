// main : stylesheet.scss


#quick-checkout{

    .font-pink{
        color: $pink;
    }
    .font-bold{
        font-weight: bold;
    }

    .card{
        border: 0;
        margin-bottom: 15px;
        .card-header{
            padding: 10px 20px;
            background: $turqoise;
            border: 0;
            h3{
                margin: 0;
                color: #fff;
            }
    
            
        }

        .card-body{
            border: 3px solid $turqoise;
            
            padding: 10px 20px;
            font-size: 14px;;
            line-height: 1.2;
            .change-link{
                color: $pink;
                font-size: 13px;;
            }

            &.no-border-top{
                border-top: 0;
            }
        }


        
    }
    
}