// main : stylesheet.scss
#select-images, #download-images{
  //margin:0 -15px;
  padding-bottom: 80px;
  .greyscale{
    img{
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
  #slideshow, #slideshowtop{
    .close{
      position: absolute;
          top: 6px;
          right: 40px;
          z-index: 90;
          background: #be2d7e;
          border-radius: 50%;
          text-shadow: none;
          line-height: normal;
          font-weight: normal;
          font-size: 16px;
          opacity: 1;
          color: white;
          padding: 5px 9px;
          width: 30px;
          height: 30px;
      @media (max-width: 768px){
          top:84px;
      }
      @media (max-width: 406px){
          top:104px;
      }
    }
  }
  #accesscodebox, #invite{
    .close{
      position: absolute;
      top: 23px;
      right: 30px;
      z-index: 90;
    }
  }

  .colourtoggle{
    margin-top: 16px; // 20px with 4px from button
    a{
      font-weight: bold;
    }
    .owl-dot{
      display: none;
    }
  }

  .title{
    @include title;

  }
  .owl-carousel .owl-item img {
    border:1px solid #dedede;
  }

  .images{
    @include greybar;
    @include imagethumbs;

    .image{
      width:50%;
      &.wide{
        width:99%;
      }

      @media (min-width: 736px) {
          width:33%;
          &.wide{
            width:66%;
          }
      }
      @media (min-width: 991px) {
          width:25%;
          &.wide{
            width:50%;
          }
      }
      @media (min-width: 1470px) {
          width:20%;
          &.wide{
            width:33%;
          }
      }
      @media (min-width: 1870px) {
          width:16.65%;
          &.wide{
            width:33.32%;
          }
      }
      .icon-svg-search{
        position: absolute;
            top: 44px;
            right: 24px;
            color: #be2d7e;
            opacity: 0.8;
            font-size: 23px;
            @media (min-width : 768px) and (min-height: 768px){
              font-size: 30px;
            }
      }
      .download{
        position: absolute;
            top: 26px;
            right: 24px;
            color: #be2d7e;
            opacity: 0.8;
            font-size: 23px;
      }
    }

  }
  .images.slideshowImages{

  }
  .owl-dots{
    button{
      background: transparent;
      border:none;
    }

  }

  .container{
    max-width: 1147px !important;
  }
}
